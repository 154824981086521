import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { SlOptionsVertical } from "react-icons/sl";
import "./homescreen.scss";

function ToolTip() {
  return (
    <div className="help-feedback">
      {["left"].map((placement) => (
        <OverlayTrigger
          trigger="click"
          key={placement}
          placement={placement}
          overlay={
            <Popover id={`popover-positioned-${placement}`}>
              <Popover.Body className="tip-style">Get Help</Popover.Body>
              <Popover.Body className="tip-style">Send Feedback</Popover.Body>
            </Popover>
          }
        >
          <button className="tooltip-btn">
            <SlOptionsVertical />
          </button>
        </OverlayTrigger>
      ))}
    </div>
  );
}

export default ToolTip;
