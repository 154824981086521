import { Routes, Route } from "react-router-dom";
import Homescreen from "../homescreen/homescreen";
import Payment from "../payment/payment";
import Success from "../success/success";
import Failed from "../failed/failed";  

const Routing = () => {
  return (
    <Routes>
      <Route exact path={"/"} element={<Homescreen />} />
      <Route exact path={"/payment"} element={<Payment />} />
      <Route exact path={"/success"} element={<Success />} />
      <Route exact path={"/failed"} element={<Failed />} />
    </Routes>
  );
};
export default Routing;
