import { getClientKeyData } from "../../api/stripe/clientKey";
import { CLIENT_KEY_SUCCESS,CLIENT_KEY_FAILURE } from "../types";

export const getClientKeySuccess = (pageData) => ({
  type: CLIENT_KEY_SUCCESS,
  payload: pageData,
});

export const getClientKeyFailure = (error) => ({
  type: CLIENT_KEY_FAILURE,
  payload: error,
});

export const getClientKey = (postData) => {
    return (dispatch) => {
        getClientKeyData(postData)
          .then((response) => {
            // console.log(response.data);
            dispatch(getClientKeySuccess(response.data));
          })
          .catch((error) => {
            dispatch(getClientKeyFailure(error.message));
            // console.log(error);
          });
      };
};

